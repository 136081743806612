import React from 'react';
import SecondaryBanner from '@mshops-components-library/secondary-banner';

const { useRenderContext } = require('../../pages/home/context');

const BannerContainer = (props) => {
  const { device } = useRenderContext();

  return <SecondaryBanner {...props} deviceType={device} />;
};

export default BannerContainer;
